import React from 'react';
import {
    HorizontalFlex,
    PadContainer,
    Title,
    VerticalFlex,
} from 'smartsuite-ui';

import systems from './systems.json';
import ButtonPanel from './ButtonPanel';

import BrockLogo from './images/BrockSolutions.svg';
import './App.css';

function App() {
    function renderSystems() {
        return Object.keys(systems).map((system) => (
            <ButtonPanel key={system} system={systems[system]} />
        ));
    }

    return (
        <div className="container">
            <VerticalFlex cssClassModifiers={['align-center']}>
                <span className="app-title">Welcome to SmartSuite</span>
                <Title>Please pick your system</Title>
                <HorizontalFlex
                    cssClassModifiers={['flex-wrap', 'justify-center']}
                >
                    {renderSystems()}
                </HorizontalFlex>
                <PadContainer />
                <HorizontalFlex>
                    <img
                        src={BrockLogo}
                        alt="BrockSolutions"
                        className="brock-logo"
                    />
                    <PadContainer>|</PadContainer>
                    <span>© 2003-2024 Brock Solutions Inc.</span>
                </HorizontalFlex>
            </VerticalFlex>
        </div>
    );
}

export default App;
