import React, { useEffect, useState } from 'react';
import { Title } from 'smartsuite-ui';

function ButtonPanel({ system }) {
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        async function fetchIcon() {
            try {
                const { default: icon } = await import(
                    `./images/${system.imageName}`
                );
                setIcon(icon);
            } catch (error) {
                console.error('Error loading icon', error);
                setIcon(null);
            }
        }
        fetchIcon();
    }, [system.imageName]);

    function displaySvgLogo(imageName) {
        if (icon) {
            return (
                <img
                    src={icon}
                    alt={imageName}
                    className="button-panel__logo"
                />
            );
        }

        return <span>{system.name}</span>;
    }

    return (
        <button
            className="button-div button-div--raised button-div--border button-div--margin-medium button-panel"
            onClick={() => window.open(system.url, '_blank')}
            type="button"
        >
            {displaySvgLogo(system.imageName)}
            <Title cssClassModifiers={['size-small']}>
                {system.systemName}
            </Title>
        </button>
    );
}

export default ButtonPanel;
